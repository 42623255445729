<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody v-if="!emailSent">
                <div class="row mb-4">
                  <div class="col-md-12">
                     <img
                        class="mx-auto d-block"
                        name="logo"
                        size="custom-size"
                        :height="66"
                        viewBox="0 0 556 134"
                        src="/logo.png"
                        :alt="appTitle"
                      />
                  </div>
                </div>

                <h1>Forgot Password</h1>
                <div v-if="serverErrors.length">
                  <CAlert
                    v-model="showDismissibleAlert"
                    color="danger"
                    closeButton
                    v-for="err in serverErrors"
                    :key="err"
                  >
                    {{ err }}
                  </CAlert>
                </div>
                <validation-observer ref="formValidator">
                  <form
                    autocomplete="off"
                    @submit.prevent="requestResetPassword"
                    method="post"
                  >
                    <ValidationProvider
                      name="Email"
                      v-slot="{ errors }"
                      vid="email"
                      :rules="{ required: true, email: true }"
                    >
                      <div class="form-group">
                        <label for="email">Email</label>
                        <CInput
                          type="email"
                          id="email"
                          placeholder="user@example.com"
                          v-model="email"
                          required
                          :addInputClasses="errors.length ? 'is-invalid' : ''"
                        />
                        <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
                      </div>
                    </ValidationProvider>
                    <button type="submit" class="btn btn-primary">
                      Send Password Reset Link
                    </button>
                  </form>
                </validation-observer>
              </CCardBody>
              <CCardBody v-else>
                <div class="row mb-4">
                  <div class="col-md-12">
                     <img
                        class="mx-auto d-block"
                        name="logo"
                        size="custom-size"
                        :height="66"
                        viewBox="0 0 556 134"
                        src="/logo.png"
                        :alt="appTitle"
                      />
                  </div>
                </div>

                <h1>Forgot Password</h1>
                <h6 class="mb-2 mt-3 text-success">
                  {{ message }}
                </h6>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      appTitle: process.env.VUE_APP_NAME,
      email: null,
      has_error: false,
      showDismissibleAlert: false,
      emailSent: false,
      serverErrors: [],
      message: "",
    };
  },
  methods: {
    requestResetPassword() {
      this.axios
        .post("auth/forgot-password", { email: this.email })
        .then((res) => {
          if (res.data.Success) {
            this.emailSent = true;
            this.message = "Password Reset Link has been sent to your email.";
            this.$toastr.s("success", "Password reset link sent to email.");
          }
          this.serverErrors = [];
          if (res.data.error) {
              this.serverErrors.push(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
            this.$refs.formValidator.setErrors(err.response.data.errors);
          }
        });
    },
  },
};
</script>
